import { useEffect, useState, useRef, useCallback } from "react";
import styles from "./MainPage.module.css"; // Import CSS module
import InfiniteScroll from "react-infinite-scroll-component";
//import searchIcon from "../../assets/search_28.svg"; // Update path if needed
import categoryIcon from "../../assets/temp_category.png"; // Update path if needed
import { useItems } from "../../hooks/useItems";
import { ItemCard } from "../../components/ui/ItemCard";
import { ItemCardSkeleton } from "../../components/ui/ItemCardSkeleton";
import { useImageLoader } from "../../hooks/useImageLoader";
import { HeartIcon, SearchIcon } from "../../components/icons"; // Import your HeartIcon component

interface MainPageProps {
  isMobile: boolean;
}

function MainPage({ isMobile }: MainPageProps) {
  useImageLoader();
  // Get state and functions for working with items via hook
  const {
    items,
    isLoading,
    error,
    hasMore,
    fetchItems,
    changeCategory,
    activeCategory,
    clearCache,
  } = useItems("All");

  const [categories, setCategories] = useState<string[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const startY = useRef(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const pullThreshold = 80; // Pixels needed to pull for refresh
  const [pullDistance, setPullDistance] = useState(0);

  // Load categories
  const fetchCategories = async () => {
    setCategories(["Music", "Art", "Coding", "Design", "Other"]);
  };

  const handleCategoryClick = (categoryName: string) => {
    const newCategory = categoryName === activeCategory ? "All" : categoryName;
    changeCategory(newCategory);
  };

  // Function to handle refresh - used by both mobile pull and desktop button
  const handleRefresh = useCallback(async () => {
    if (refreshing) {
      console.log("Already refreshing, ignoring request");
      return;
    }

    console.log("Starting refresh process");
    setRefreshing(true);

    try {
      // Clear cache to force database refresh
      console.log("Calling clearCache()");
      const cacheCleared = await clearCache();
      console.log("Cache cleared:", cacheCleared);

      // fetchItems is no longer needed here as clearCache triggers the useEffect in useItems
    } catch (err) {
      console.error("Refresh error:", err);
    } finally {
      // Add a small delay for better UX
      setTimeout(() => {
        console.log("Completing refresh");
        setRefreshing(false);
        setPullDistance(0); // Reset pull distance
      }, 1000); // Increased for better visual feedback
    }
  }, [clearCache, refreshing]);

  // Mobile pull-to-refresh handlers
  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    // Only allow pull-to-refresh at the top of the page
    if (window.scrollY === 0) {
      startY.current = e.touches[0].clientY;
      console.log("Touch start at Y:", startY.current);
    }
  }, []);

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (startY.current === 0 || refreshing) return;

      const currentY = e.touches[0].clientY;
      const diff = currentY - startY.current;

      // Only allow pulling down, not up
      if (diff > 0 && window.scrollY === 0) {
        // Calculate resistance - the further you pull, the harder it gets
        const newPullDistance = Math.min(
          Math.pow(diff, 0.8),
          pullThreshold * 1.5
        );
        setPullDistance(newPullDistance);

        // Prevent default scrolling behavior
        e.preventDefault();
      }
    },
    [pullThreshold, refreshing]
  );

  const handleTouchEnd = useCallback(() => {
    if (pullDistance > pullThreshold && !refreshing) {
      console.log("Pull threshold reached, triggering refresh");
      handleRefresh();
    } else {
      setPullDistance(0); // Reset if not enough to trigger refresh
    }
    startY.current = 0;
  }, [pullDistance, pullThreshold, refreshing, handleRefresh]);

  // Load initial data
  useEffect(() => {
    fetchCategories();
    // fetchItems() is called inside useItems hook
    console.log("Component mounted, isMobile:", isMobile);
  }, []);

  // Generate skeleton placeholders
  const renderSkeletons = () => {
    // Create an array of 6 skeletons or based on screen width
    return Array(6)
      .fill(0)
      .map((_, index) => <ItemCardSkeleton key={`skeleton-${index}`} />);
  };

  return (
    <div
      className={styles.mainPage}
      ref={scrollRef}
      onTouchStart={isMobile ? handleTouchStart : undefined}
      onTouchMove={isMobile ? handleTouchMove : undefined}
      onTouchEnd={isMobile ? handleTouchEnd : undefined}
    >
      {isMobile && (pullDistance > 0 || refreshing) && (
        <div
          className={styles.pullToRefresh}
          style={{
            height: refreshing ? `${pullThreshold}px` : `${pullDistance}px`,
            opacity: refreshing ? 1 : Math.min(pullDistance / pullThreshold, 1),
          }}
        >
          <div
            className={`${styles.refreshIcon} ${
              refreshing ? styles.spinning : ""
            }`}
            style={{
              transform: refreshing
                ? "rotate(0deg)"
                : `rotate(${(pullDistance / pullThreshold) * 180}deg)`,
            }}
          >
            {/* iOS-style gear icon */}
            <svg viewBox="0 0 24 24" width="28" height="28">
              <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
            </svg>
          </div>
        </div>
      )}

      <div className={styles.header}>
        <div className={styles.headerSearch}>
          <form className={styles.searchForm}>
            <div className={styles.searchContainer}>
              <div className={styles.searchIcon}>
                <SearchIcon width="16" height="16" viewBox="0 0 22 22" />
              </div>
              <input
                type="search"
                placeholder="Search"
                className={styles.searchInput}
              />
            </div>
          </form>
          <div className={styles.headerActions}>
            {!isMobile && (
              <button
                className={styles.refreshButton}
                onClick={handleRefresh}
                disabled={refreshing}
                aria-label="Refresh"
              >
                <svg
                  className={`${styles.refreshIcon} ${
                    refreshing ? styles.spinning : ""
                  }`}
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                >
                  <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 9h7V2l-2.35 4.35z" />
                </svg>
              </button>
            )}
            <div className={styles.heartContainer}>
              <HeartIcon width="24" height="21" />
            </div>
          </div>
        </div>
        <div className={styles.categorySwitcher}>
          {categories.map((category, index) => (
            <div
              key={index}
              className={`${styles.categoryItem} ${
                activeCategory === category ? styles.categoryItemActive : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              <img
                src={categoryIcon}
                className={styles.categoryIcon}
                alt={category}
              />
              <a className={styles.categoryName}>{category}</a>
            </div>
          ))}
        </div>
      </div>

      {/* Show skeletons if we're loading initial data */}
      {isLoading && items.length === 0 ? (
        <div className={styles.infiniteScrollComponent}>
          {renderSkeletons()}
        </div>
      ) : (
        <InfiniteScroll
          dataLength={items.length}
          next={() => fetchItems()}
          hasMore={hasMore}
          loader={
            <div className={styles.infiniteScrollComponent}>
              {renderSkeletons()}
            </div>
          }
          scrollableTarget="root"
          endMessage={
            <div className={styles.endPage}>
              <p className={styles.endMessage}>
                -----------------------------------
              </p>
            </div>
          }
          scrollThreshold={0.9}
          className={styles.infiniteScrollComponent}
        >
          {items.map((item) => (
            <ItemCard
              key={item.id}
              id={item.id}
              title={item.title}
              description={item.description}
              category={item.category}
              created_at={item.created_at}
            />
          ))}
        </InfiniteScroll>
      )}

      {error && <div className={styles.errorMessage}>{error}</div>}
      {isLoading && items.length === 0 && (
        <div className={styles.loadingMessage}>Загрузка товаров...</div>
      )}
    </div>
  );
}

export default MainPage;
