import React from "react";
import IconBase, { IconBaseProps } from "./IconBase";

const SearchIcon: React.FC<IconBaseProps> = (props) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9046 1.95005C5.06332 1.95005 1.95 5.06266 1.95 8.90146C1.95 12.7403 5.06332 15.8529 8.9046 15.8529C10.8263 15.8529 12.5643 15.0751 13.824 13.8151C15.0826 12.5562 15.8592 10.8204 15.8592 8.90146C15.8592 5.06266 12.7459 1.95005 8.9046 1.95005ZM0.0499992 8.90146C0.0499992 4.0126 4.0147 0.0500488 8.9046 0.0500488C13.7945 0.0500488 17.7592 4.0126 17.7592 8.90146C17.7592 11.0025 17.026 12.9336 15.8029 14.4512L21.6722 20.3288C22.043 20.7 22.0425 21.3015 21.6713 21.6723C21.3 22.043 20.6985 22.0426 20.3278 21.6713L14.4594 15.7948C12.9408 17.019 11.0077 17.7529 8.9046 17.7529C4.0147 17.7529 0.0499992 13.7903 0.0499992 8.90146Z"
    />
  </IconBase>
);

export default SearchIcon;
