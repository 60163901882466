import { NavLink } from "react-router-dom";
import "../../styles/Navbar.css";

import { SearchIcon, YourItemsIcon, SettingsIcon } from "../icons";

// Импортируем наши SVG иконки
//import searchIcon from "../../assets/search_28.svg";
//import botMenuIcon from "../../assets/bot_menu_28.svg";
//import personIcon from "../../assets/person_24.svg";

function Navbar() {
  //JSX
  return (
    <nav className="navbar">
      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive ? "nav-item active" : "nav-item"
        }
        end
      >
        <div className="nav-icon">
          <SearchIcon size={22} />
        </div>

        <span>Search</span>
      </NavLink>
      <NavLink
        to="/items"
        className={({ isActive }) =>
          isActive ? "nav-item active" : "nav-item"
        }
        end
      >
        <div className="nav-icon">
          <YourItemsIcon size={22} />
        </div>
        <span>Your Items</span>
      </NavLink>
      <NavLink
        to="/settings"
        className={({ isActive }) =>
          isActive ? "nav-item active" : "nav-item"
        }
        end
      >
        <div className="nav-icon">
          <SettingsIcon width="22" height="22" viewBox="0 0 24 24" />
        </div>
        <span>Settings</span>
      </NavLink>
    </nav>
  );
}

export default Navbar;
