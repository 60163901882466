import { TokenInfo } from "../types";

/**
 * Default token info object with invalid state
 */
export const defaultTokenInfo: TokenInfo = {
  isValid: false,
  userId: null,
  expiresIn: null,
};

/**
 * Validates JWT token and extracts information from it
 * @param token - JWT token to validate
 * @returns TokenInfo object with validation results
 */
export const validateToken = (token: string | null): TokenInfo => {
  if (!token) return defaultTokenInfo;

  try {
    // Get the payload part of the token
    const payload = token.split(".")[1];
    // Decode from base64
    const decoded = JSON.parse(atob(payload));

    // Get expiration time and current time
    const exp = decoded.exp;
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if token is still valid
    const isValid = currentTime < exp;

    // Calculate remaining time in seconds
    const expiresIn = isValid ? exp - currentTime : null;

    // Get user ID from different possible fields
    const userId = decoded.user_id || decoded.sub || decoded.id || null;

    return { isValid, userId, expiresIn };
  } catch (error) {
    console.error("Error validating token:", error);
    return defaultTokenInfo;
  }
};

/**
 * Gets the token from localStorage
 */
export const getStoredToken = (): string | null => {
  return localStorage.getItem("access_token");
};

/**
 * Stores token in localStorage
 * @param token - Token to store
 */
export const storeToken = (token: string): void => {
  localStorage.setItem("access_token", token);
};

/**
 * Removes token from localStorage
 */
export const removeToken = (): void => {
  localStorage.removeItem("access_token");
};
