import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import testImage from "../../assets/300x300.jpg";
import { formatRelativeTime } from "../../utils/dateFormatter";
import { Item } from "../../types";
import styles from "./ItemCard.module.css";

// Use Item interface from types as a base for props
// Delete tg_id and user_id from the interface
type ItemCardProps = Omit<Item, "user_id" | "status">;

export const ItemCard: FC<ItemCardProps> = ({
  id,
  title,
  description,
  category,
  created_at,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    // Pass current path as referrer and search parameters
    navigate(`/item/${id}`, {
      state: {
        referrer: location.pathname,
        referrerSearch: location.search,
      },
    });
  };

  return (
    <div className={styles.itemCard} onClick={handleClick}>
      <div className={styles.itemImg}>
        <img src={testImage} alt={title} />
      </div>
      <div className={styles.itemContent}>
        <a className={styles.itemTitle}>{title}</a>
        <a className={styles.itemDesc}>{description}</a>
        <a className={styles.itemCategory}>{category}</a>
        <a className={styles.itemTime}>{formatRelativeTime(created_at)}</a>
      </div>
    </div>
  );
};

export default ItemCard;
