import { useState, useEffect, useCallback } from "react";
import { authUser } from "../services/api";
import { validateToken, storeToken, getStoredToken } from "../utils/tokenUtils";
import { TokenInfo } from "../types";

export const useAuth = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>({
    isValid: false,
    userId: null,
    expiresIn: null,
  });

  /**
   * Авторизация пользователя с использованием Telegram initData
   * @param initDataRaw - Raw данные из Telegram SDK
   */
  const authorize = useCallback(async (initDataRaw: string) => {
    try {
      const authData = await authUser(initDataRaw);

      // Сохраняем токен в localStorage
      storeToken(authData.access_token);

      // Проверяем токен и обновляем состояние
      const info = validateToken(authData.access_token);
      setTokenInfo(info);
      setIsAuthorized(info.isValid);

      return info;
    } catch (error) {
      console.error("Ошибка авторизации:", error);
      throw error;
    }
  }, []);

  // При загрузке хука проверяем токен в localStorage
  useEffect(() => {
    const token = getStoredToken();
    const info = validateToken(token);

    setTokenInfo(info);
    setIsAuthorized(info.isValid);
  }, []);

  return {
    isAuthorized,
    tokenInfo,
    authorize,
  };
};
