import { useState, useEffect, useCallback, useRef } from "react";
import { getItems } from "../services/api";
import { Item } from "../types";
//import { useAdaptiveLimit } from "./useAdaptiveLimit";

export const useItems = (initialCategory = "All") => {
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [activeCategory, setActiveCategory] = useState(initialCategory);

  // Используем useRef для отслеживания запросов в процессе
  const isFetchingRef = useRef(false);

  const limit = 10;
  // Get adaptive limit based on screen size
  //const limit = useAdaptiveLimit(16, 2);
  console.log("Limit:", limit);

  // Load initial data from session storage
  useEffect(() => {
    const loadFromSessionStorage = () => {
      try {
        const storedItemsData = sessionStorage.getItem(
          `marketplace_items_${activeCategory}`
        );
        const storedSkipData = sessionStorage.getItem(
          `marketplace_skip_${activeCategory}`
        );
        const storedHasMoreData = sessionStorage.getItem(
          `marketplace_hasMore_${activeCategory}`
        );

        if (storedItemsData) {
          const parsedItems = JSON.parse(storedItemsData);
          setItems(parsedItems);

          if (storedSkipData) {
            setSkip(parseInt(storedSkipData, 10));
          }

          if (storedHasMoreData) {
            setHasMore(JSON.parse(storedHasMoreData));
          }

          return parsedItems.length > 0;
        }
      } catch (err) {
        console.error("Error loading data from session storage:", err);
      }
      return false;
    };

    const hasStoredData = loadFromSessionStorage();

    // Only fetch initial data if nothing was loaded from session storage
    if (!hasStoredData) {
      fetchItems(true);
    }
  }, [activeCategory]);

  // Update session storage when items, skip, or hasMore change
  useEffect(() => {
    if (items.length > 0) {
      try {
        sessionStorage.setItem(
          `marketplace_items_${activeCategory}`,
          JSON.stringify(items)
        );
        sessionStorage.setItem(
          `marketplace_skip_${activeCategory}`,
          skip.toString()
        );
        sessionStorage.setItem(
          `marketplace_hasMore_${activeCategory}`,
          JSON.stringify(hasMore)
        );
      } catch (err) {
        console.error("Error saving data to session storage:", err);
      }
    }
  }, [items, skip, hasMore, activeCategory]);

  /**
   * Загрузка товаров
   * @param reset - Сбросить существующие данные
   */
  const fetchItems = useCallback(
    async (reset = false) => {
      // Предотвращаем параллельные запросы
      if (isFetchingRef.current || (!hasMore && !reset)) return;

      isFetchingRef.current = true;
      setIsLoading(true);
      setError(null);

      try {
        // Если сбрасываем, начинаем с начала
        const currentSkip = reset ? 0 : skip;

        console.log("перед отправкой запроса: ", limit);

        const newItems = await getItems(activeCategory, currentSkip, limit);

        if (newItems.length === 0) {
          setHasMore(false);
        } else {
          // Обновляем список товаров
          setItems((prev) => (reset ? newItems : [...prev, ...newItems]));
          setSkip((prev) => (reset ? limit : prev + limit));
        }
      } catch (err) {
        setError("Не удалось загрузить товары. Пожалуйста, попробуйте позже.");
        console.error("Ошибка при загрузке товаров:", err);
      } finally {
        setIsLoading(false);
        isFetchingRef.current = false;
      }
    },
    [activeCategory, hasMore, skip]
  );

  /**
   * Смена категории товаров
   * @param category - Новая категория
   */
  const changeCategory = useCallback(
    (category: string) => {
      if (category === activeCategory) return;

      setActiveCategory(category);
      setItems([]);
      setSkip(0);
      setHasMore(true);
      setError(null);

      // fetchItems will be called in the useEffect that monitors activeCategory
    },
    [activeCategory]
  );

  // No need for this useEffect anymore as we're handling loading in the first useEffect
  // useEffect(() => {
  //   fetchItems(true);
  // }, [activeCategory]);

  // Add a function to clear the cache if needed
  const clearCache = useCallback(() => {
    try {
      sessionStorage.removeItem(`marketplace_items_${activeCategory}`);
      sessionStorage.removeItem(`marketplace_skip_${activeCategory}`);
      sessionStorage.removeItem(`marketplace_hasMore_${activeCategory}`);
      fetchItems(true);
    } catch (err) {
      console.error("Error clearing cache:", err);
    }
  }, [activeCategory, fetchItems]);

  return {
    items,
    isLoading,
    error,
    hasMore,
    fetchItems,
    changeCategory,
    activeCategory,
    clearCache,
  };
};
