import { useEffect, useRef } from "react";

/**
 * Hook that handles graceful image loading
 * Makes images appear only when fully loaded
 * Also uses MutationObserver to detect new images added to DOM
 */
export function useImageLoader() {
  // Keep track of observer instance for cleanup
  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    // Function to handle image load
    const handleImageLoad = (img: HTMLImageElement) => {
      // Add loaded class to make image visible
      img.classList.add("loaded");
    };

    // Process a single image
    const processImage = (img: HTMLImageElement) => {
      // Skip if already processed
      if (img.classList.contains("processed")) {
        return;
      }

      // Mark as processed to avoid duplicate processing
      img.classList.add("processed");

      if (img.complete) {
        // Image is already loaded (from cache)
        handleImageLoad(img);
      } else {
        // Image is still loading
        img.addEventListener("load", () => handleImageLoad(img), {
          once: true,
        });

        // Handle error case as well
        img.addEventListener(
          "error",
          () => {
            console.warn("Failed to load image:", img.src);
            // Optional: add a class for styling error images
            img.classList.add("error");
          },
          { once: true }
        );
      }
    };

    // Process all images currently in DOM
    const processAllImages = () => {
      const images = document.querySelectorAll("img:not(.processed)");
      images.forEach((img) => processImage(img as HTMLImageElement));
    };

    // Initial processing
    processAllImages();

    // Set up MutationObserver to detect new images
    observerRef.current = new MutationObserver((mutations) => {
      let shouldProcess = false;

      // Check if any mutations added new nodes
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          shouldProcess = true;
        }
      });

      // Only reprocess all images if new nodes were added
      if (shouldProcess) {
        // Small delay to ensure all DOM updates are complete
        setTimeout(processAllImages, 50);
      }
    });

    // Start observing the entire document for changes
    observerRef.current.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Cleanup function
    return () => {
      // Disconnect observer
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);
}
