// components/icons/IconBase.tsx
import React from "react";

export interface IconBaseProps {
  size?: number | string;
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
  onClick?: () => void;
  viewBox?: string; // Add viewBox as a prop
}

const IconBase: React.FC<IconBaseProps & React.SVGProps<SVGSVGElement>> = ({
  children,
  size,
  width,
  height,
  color = "currentColor",
  className,
  viewBox,
  ...props
}) => {
  // Calculate width and height values
  const finalWidth = size || width || 24;
  const finalHeight = size || height || 24;

  // Generate viewBox if not provided
  const finalViewBox = viewBox || `0 0 ${finalWidth} ${finalHeight}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={finalWidth}
      height={finalHeight}
      fill="none"
      viewBox={finalViewBox}
      className={className}
      color={color}
      {...props}
    >
      {children}
    </svg>
  );
};

export default IconBase;
