// components/icons/HeartIcon.tsx
import React from "react";
import IconBase, { IconBaseProps } from "./IconBase";

const HeartIcon: React.FC<IconBaseProps> = (props) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 2.12561C11.0056 1.19416 9.65734 0.447971 8.17716 0.176099C6.41552 -0.14747 4.46748 0.205139 2.77497 1.69982C1.64709 2.69587 0.941524 4.26291 0.707722 5.95627C0.471949 7.66389 0.701543 9.58502 1.54155 11.3654C2.14927 12.6534 3.59604 14.2737 5.09942 15.7651C6.63581 17.2894 8.34646 18.7887 9.59833 19.8485C10.9901 21.0267 13.01 21.0267 14.4018 19.8485C15.6537 18.7888 17.3643 17.2894 18.9007 15.7652C20.4041 14.2737 21.8509 12.6534 22.4586 11.3654C23.2986 9.58504 23.5282 7.66391 23.2924 5.95628C23.0586 4.26292 22.3531 2.69589 21.2252 1.69984C19.5327 0.205155 17.5846 -0.147458 15.823 0.176107C14.3428 0.447976 12.9946 1.19416 12.0001 2.12561ZM7.85199 1.94648C6.57826 1.71253 5.20433 1.95584 3.96647 3.04902C3.24692 3.68446 2.68346 4.80712 2.49081 6.20246C2.30012 7.58353 2.48663 9.1501 3.16945 10.5973C3.62417 11.561 4.84583 12.978 6.36714 14.4873C7.85544 15.9638 9.52573 17.4287 10.7613 18.4746C11.4819 19.0846 12.5182 19.0846 13.2388 18.4746C14.4744 17.4287 16.1447 15.9638 17.633 14.4873C19.1543 12.978 20.376 11.561 20.8307 10.5973C21.5135 9.15012 21.7 7.58354 21.5093 6.20247C21.3167 4.80713 20.7532 3.68448 20.0337 3.04903C18.7958 1.95586 17.4219 1.71254 16.1482 1.94649C14.8438 2.18607 13.6456 2.93039 12.8769 3.80327C12.4125 4.33069 11.5876 4.33069 11.1232 3.80327C10.3546 2.93039 9.15635 2.18606 7.85199 1.94648Z"
    />
  </IconBase>
);

export default HeartIcon;
