import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles/index.css";
import App from "./App.tsx";

// Import Buffer correctly
import { Buffer } from "buffer";

// Extend Window interface
declare global {
  interface Window {
    Buffer: typeof Buffer;
  }
}

// Make Buffer globally available
window.Buffer = Buffer;
createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
