import { FC } from "react";
import styles from "./ItemCardSkeleton.module.css";

export const ItemCardSkeleton: FC = () => {
  return (
    <div className={styles.skeleton}>
      <div className={styles.skeletonImg}></div>
      <div className={styles.skeletonContent}>
        <div className={styles.skeletonTitle}></div>
        <div className={styles.skeletonDesc}></div>
        <div className={styles.skeletonCategory}></div>
        <div className={styles.skeletonTime}></div>
      </div>
    </div>
  );
};

export default ItemCardSkeleton;
