/**
 * API service for the application to interact with the backend
 */

const API_URL = "https://meldone.eu.org/api";

/**
 * Получение заголовков с токеном авторизации
 */
export const getAuthHeaders = () => {
  const token = localStorage.getItem("access_token");
  return {
    "Content-Type": "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
};

/**
 * Авторизация пользователя
 * @param initDataRaw - Сырые данные инициализации от Telegram
 */
export const authUser = async (initDataRaw: string) => {
  try {
    const response = await fetch(`${API_URL}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ init_data_raw: initDataRaw }),
    });

    if (!response.ok) {
      throw new Error(`Ошибка авторизации: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Ошибка авторизации:", error);
    throw error;
  }
};

/**
 * Get items by category
 * @param category - Category of items to get
 * @param skip - how many items to skip (for pagination)
 * @param limit - how many items to get
 */
export const getItems = async (category = "All", skip = 0, limit = 6) => {
  try {
    const response = await fetch(
      `${API_URL}/items/active?category=${category}&skip=${skip}&limit=${limit}`,
      {
        headers: getAuthHeaders(),
      }
    );

    if (!response.ok) {
      throw new Error(`Ошибка получения товаров: ${response.status}`);
    }

    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error("Ошибка получения товаров:", error);
    throw error;
  }
};

/**
 * Получение данных о товаре по id
 * @param itemId - ID товара
 */
export const getItem = async (itemId: string = "0") => {
  try {
    const response = await fetch(`${API_URL}/item?item_id=${itemId}`, {
      headers: getAuthHeaders(),
    });

    if (!response.ok) {
      throw new Error(`Ошибка получения товара: ${response.status}`);
    }

    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error("Ошибка получения товара:", error);
    throw error;
  }
};

/**
 * Добавление нового товара
 * @param itemData - Данные товара
 */
export const addItem = async (itemData: {
  title: string;
  description: string;
  category: string;
}) => {
  try {
    const response = await fetch(`${API_URL}/items/add`, {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify(itemData),
    });

    if (!response.ok) {
      throw new Error(`Ошибка добавления товара: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Ошибка добавления товара:", error);
    throw error;
  }
};

export const getUserItems = async () => {
  try {
    const response = await fetch(`${API_URL}/items/get`, {
      headers: getAuthHeaders(),
    });

    if (!response.ok) {
      throw new Error(`Ошибка получения товара: ${response.status}`);
    }

    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error("Ошибка получения товара:", error);
    throw error;
  }
};
