import { formatDistanceToNow } from "date-fns";
import { enUS } from "date-fns/locale";

/**
 * Formats a date string to "time ago" format (e.g. "2 hours ago")
 * @param dateString - Date string to format
 * @returns Formatted date string or empty string if input is invalid
 */
export const formatRelativeTime = (dateString?: string): string => {
  if (!dateString) return "";

  try {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true, locale: enUS });
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString;
  }
};

/**
 * Formats a date to a readable string in English format
 * @param date - Date object or string to format
 * @returns Formatted date string or empty string if input is invalid
 */
export const formatDate = (date?: Date | string): string => {
  if (!date) return "";

  try {
    const dateObj = typeof date === "string" ? new Date(date) : date;
    return new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(dateObj);
  } catch (error) {
    console.error("Error formatting date:", error);
    return typeof date === "string" ? date : "";
  }
};
