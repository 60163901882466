import { useState, useEffect, useRef, useCallback } from "react";
import {
  init,
  User,
  initData,
  miniApp,
  themeParams,
  viewport,
  backButton,
  secondaryButton,
  on,
} from "@telegram-apps/sdk";

export const useTelegramSDK = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [userData, setUserData] = useState<User | undefined>(undefined);
  const [rawInitData, setRawInitData] = useState<string>("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const initializingRef = useRef(false);

  // Extract color update logic to a separate function for reuse
  const updateAppColors = useCallback(() => {
    // Set header color if available
    if (miniApp.setHeaderColor.isAvailable()) {
      miniApp.setHeaderColor(
        themeParams.secondaryBackgroundColor() || "secondary_bg_color"
      );
    }

    // Set bottom bar color if available
    if (miniApp.setBottomBarColor.isAvailable()) {
      miniApp.setBottomBarColor(
        themeParams.secondaryBackgroundColor() || "secondary_bg_color"
      );
    }

    // Set background color if available
    if (miniApp.setBackgroundColor.isAvailable()) {
      miniApp.setBackgroundColor(
        themeParams.secondaryBackgroundColor() || "secondary_bg_color"
      );
    }

    console.log("App colors updated successfully");
  }, []);

  // Separate effect for theme change listener
  useEffect(() => {
    // Only set up the listener if we're initialized
    if (!isInitialized) return;

    // Set flag to prevent duplicate listeners
    //listenerSetRef.current = true;

    console.log("Setting up theme change listener");

    // Subscribe to theme changes
    const removeThemeListener = on("theme_changed", () => {
      console.log("Theme changed detected");
      updateAppColors();
    });

    // Apply initial colors
    updateAppColors();

    // Clean up listener when component unmounts
    return () => {
      console.log("Cleaning up theme change listener");
      if (typeof removeThemeListener === "function") {
        removeThemeListener();
      }
      //listenerSetRef.current = false;
    };
  }, [isInitialized, updateAppColors]);

  useEffect(() => {
    const initializeSDK = async () => {
      // Предотвращаем повторную инициализацию
      if (initializingRef.current) {
        console.log("Инициализация уже выполняется, пропускаем...");
        return;
      }

      initializingRef.current = true;
      const hash = window.location.hash.slice(1);
      const params = new URLSearchParams(hash);
      const platform = params.get("tgWebAppPlatform") || "tdesktop";
      document.body.classList.add(`platform-${platform}`);
      console.log("Платформа:", platform);

      // Determine if it's a mobile device based on platform
      // Mobile: android, ios
      // Desktop: tdesktop, weba, web, macos
      const mobilePatforms = ["android", "ios"];
      const isMobileDevice = mobilePatforms.includes(platform);
      setIsMobile(isMobileDevice);
      console.log("Is mobile device:", isMobileDevice);

      try {
        // Инициализация SDK
        init();

        // Монтирование компонентов Telegram
        if (miniApp.mount.isAvailable()) {
          await miniApp.mount();

          if (miniApp.bindCssVars.isAvailable()) {
            miniApp.bindCssVars();
          }
          if (themeParams.bindCssVars.isAvailable()) {
            themeParams.bindCssVars();
          }
        }

        // Получаем viewport
        if (viewport.mount.isAvailable()) {
          await viewport.mount();
          if (viewport.bindCssVars.isAvailable()) {
            viewport.bindCssVars();
          }
        }

        // Настраиваем кнопки
        if (backButton.mount.isAvailable()) {
          backButton.mount();
        }

        if (secondaryButton.mount.isAvailable()) {
          secondaryButton.mount();

          if (secondaryButton.setParams.isAvailable()) {
            secondaryButton.setParams({
              backgroundColor: themeParams.buttonColor(),
              hasShineEffect: true,
              isEnabled: true,
              isLoaderVisible: false,
              isVisible: false,
              position: "top",
              text: "Back",
              textColor: themeParams.buttonTextColor(),
            });
          }
        }

        // Получаем данные пользователя
        initData.restore();

        const user = initData.user();
        const rawData = initData.raw() || "";

        console.log("Получены данные пользователя из Telegram SDK");

        setUserData(user ?? undefined);
        setRawInitData(rawData);
        setIsInitialized(true);
      } catch (error) {
        console.error("Ошибка при инициализации Telegram SDK:", error);
        setIsInitialized(true); // Помечаем как инициализированное даже при ошибке
      } finally {
        initializingRef.current = false;
      }
    };

    initializeSDK();
  }, []);

  return {
    isInitialized,
    userData,
    rawInitData,
    isMobile,
  };
};
